// @ts-nocheck

const PaymentMethodsView = () =>  import("../views/PaymentMethodsView.vue");
const DatabasesView = () =>  import("../views/DatabasesView.vue");
const MyProjectsView = () =>  import("../views/MyProjectsView.vue");
const CustomerPortalPage = () =>  import("../views/CustomerPortalPage.vue");
const BillingPage = () =>  import("../views/BillingPage/Index.vue");
const MyAccountPage = () =>  import("../views/MyAccountPage.vue");
const ChangePasswordPage = () =>  import("../views/ChangePasswordPage.vue");
const ServiceDetailsView = () =>  import("../views/ServiceDetailsView.vue");
const OverviewView = () =>  import("../views/overview/Index.vue");
const KubernetesView = () =>  import("../views/kubernetes/Index.vue");
const ComputeResourcesView = () =>  import("../views/ComputeResourcesPage/index.vue");
const VolumesView = () => import('../views/VolumesView.vue');
const DevelopmentPoint = () => import('../views/development-point/Index.vue');
const DevelopmentPointHistory = () => import('../views/development-point/History.vue');
const PaymentPage = () => import('../views/PaymentPage.vue')
const PaymentMethodsCreate = () => import('../views/Payment/CreateView.vue')
const TeamsView = () => import('../views/overview/TeamsView.vue')
//Project views
const ListViewProject = () => import('../views/Project/ListView.vue')
const CreateViewProject = () => import('../views/Project/CreateView.vue')
const EditViewProject = () => import('../views/Project/EditView.vue')
const ListBoardView = () => import('../views/overview/BoardView.vue')

interface IMetaObject {
  layout?: string,
  is_auth_protected?: boolean,
  only_admin?: boolean,
}

interface IRoute {
  path: string,
  name: string,
  component: object,
  meta: IMetaObject,
}

const routes: Array<IRoute> = [
  {
    path: '/compute_resources/:project_id',
    name: 'ComputeResourcesPage',
    component: ComputeResourcesView,
    meta: {
      layout: "LayoutServices",
      is_auth_protected: true
    }
  },
  {
    path: "/payment_methods/:customer_id",
    name: "MyPaymentMethodsPage",
    component: PaymentMethodsView,
    meta: {
      layout: "LayoutMyAccount",
      is_auth_protected: true
    }
  },
  {
    path: "/volumes/:project_id",
    name: "VolumesPage",
    component: VolumesView,
    meta: {
      layout: "LayoutServices",
      is_auth_protected: true
    }
  },
  {
    path: "/databases/:project_id",
    name: "MyDatabasesPage",
    component: DatabasesView,
    meta: {
      layout: "LayoutServices",
      is_auth_protected: true
    }
  },
  {
    path: "/projects/:customer_id",
    name: "MyProjectsPage",
    component: ListViewProject,
    meta: {
      is_auth_protected: true
    }
  },
  {
    path: "/projects/:customer_id/create",
    name: "MyProjectsPageCreate",
    component: CreateViewProject,
    meta: {
      is_auth_protected: true
    }
  },
  {
    path: "/projects/:customer_id/edit/:id",
    name: "MyProjectsPageEdit",
    component: EditViewProject,
    meta: {
      is_auth_protected: true
    }
  },

  {
    path: "/development-point",
    name: "DevelopmentPointPage",
    component: DevelopmentPoint,
    meta: {
      is_auth_protected: true
    }
  },
  {
    path: "/development-point/history/:id",
    name: "DevelopmentPointHistoryPage",
    component: DevelopmentPointHistory,
    meta: {
      layout: "LayoutServices",
      is_auth_protected: true
    }
  },
  {
    path: '/development-point/:id/pay',
    name: 'PaymentPage',
    component: PaymentPage,
    props: true
  },
  {
    path: "/payment_methods/create",
    name: "PaymentMethodsCreate",
    component: PaymentMethodsCreate,
    meta: {
      layout: "LayoutMyAccount",
      is_auth_protected: true
    }
  },
  {
    path: "/customer-portal",
    name: "CustomerPortalPage",
    component: CustomerPortalPage,
    meta: {
      is_auth_protected: true,
    }
  },
  {
    path: "/billing/:customer_id",
    name: "BillingPage",
    component: BillingPage,
    meta: {
      is_auth_protected: true,
    }
  },
  {
    path: "/my-account",
    name: "MyAccountPage",
    component: MyAccountPage,
    meta: {
      layout: "LayoutMyAccount",
      is_auth_protected: true
    }
  },
  {
    path: "/change-password",
    name: "ChangePasswordPage",
    component: ChangePasswordPage,
    meta: {
      layout: "LayoutMyAccount",
      is_auth_protected: true
    }
  },
  {
    path: "/services/:project_id/:metric",
    name: "ServiceDetailsPage",
    component: ServiceDetailsView,
    meta: {
      layout: "LayoutServices",
      is_auth_protected: true
    }
  },
  {
    path: "/overview/:project_id",
    name: "OverviewPage",
    component: OverviewView,
    meta: {
      layout: "LayoutServices",
      is_auth_protected: true
    }
  },
  {
    path: "/teams",
    name: "TeamsPage",
    component: TeamsView,
    meta: {
      layout: "LayoutServices",
      is_auth_protected: true
    }
  },
  {
    path: "/boards",
    name: "ListBoardView",
    component: ListBoardView,
    meta: {
      layout: "LayoutServices",
      is_auth_protected: true
    }
  },
  {
    path: "/kubernetes/:project_id",
    name: "KubernetesPage",
    component: KubernetesView,
    meta: {
      layout: "LayoutServices",
      is_auth_protected: true
    }
  }
]

export default routes;