<template>
  <HeaderComponent @expandListBlock="handleExpand" />

  <main>
    <section class="container">
      <div class="row">
        <div :class="menuClass">
          <div class="stat-card card">
            <ul
              v-if="store._customer && isAdmin"
              class="w-100"
              style="margin-top: 15px"
            >
              <li class="mb-3">
                <h3>Manage</h3>
              </li>
              <li
                v-for="(link, i) in state.links"
                :key="i"
                :class="{
                  disabled: link.disabled,
                  current: isCurrent(link.path),
                }"
                class="mb-1"
              >
                <router-link :to="link.route" :id="`manage-${link.name}`">
                  <button class="">
                    {{ link.label }}
                  </button>
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <div :class="contentClass" id="layout-main-content">
          <slot></slot>
        </div>
      </div>
    </section>
  </main>

  <FooterComponent />
</template>

<script setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import FooterComponent from "./Footer/FooterComponent.vue";
import HeaderComponent from "./Header/HeaderComponent.vue";
import { useCustomerStore } from "../stores/customer";

const route = useRoute();
const store = useCustomerStore();
const state = {
  links: [
    {
      route: "/admin/customers",
      label: "Customers",
      path: "customers",
      icon: "",
      disabled: false,
      name: "customers",
    },
    {
      route: "/admin/teams",
      label: "Teams",
      path: "teams",
      icon: "",
      disabled: false,
      name: "teams",
    },
    {
      route: "/admin/projects",
      label: "Projects",
      path: "projects",
      icon: "",
      disabled: false,
      name: "projects",
    },
    {
      route: "/admin/boards",
      label: "Boards",
      path: "boards",
      icon: "",
      disabled: false,
      name: "boards",
    },
    {
      route: "/admin/rights-profiles",
      label: "Rights",
      path: "rights-profiles",
      icon: "",
      disabled: false,
      name: "profiles",
    },
    {
      route: "/admin/products",
      label: "Products",
      path: "products",
      icon: "",
      disabled: false,
      name: "products",
    },
    {
      route: "/admin/credit-cards",
      label: "Credit Cards",
      path: "credit-cards",
      icon: "",
      disabled: false,
      name: "admin-credit-cards",
    },
    {
      route: "/admin/configs/main",
      label: "Configs",
      path: "configs",
      icon: "",
      disabled: false,
      name: "configs",
    },
    {
      route: "/admin/server_logs",
      label: "Server logs",
      path: "server_logs",
      icon: "",
      disabled: false,
      name: "server-logs",
    },
    
  ],
};

const isMenuExpanded = ref(false);

const isAdmin = computed(() => {
  const customer = store._customer;
  if (customer.role == undefined) return false;
  else return customer.role == "admin";
});

function isCurrent(link) {
  if (link == "/") return false;

  if (route.path === "/admin" && link === "customers") {
    return true;
  }

  return route.path.includes(link);
}

const menuClass = computed(() => {
  return isMenuExpanded.value ? "col-lg-2" : "col-lg-3";
});
const contentClass = computed(() => {
  return isMenuExpanded.value ? "col-lg-10" : "col-lg-9";
});

const handleExpand = (value) => {
  isMenuExpanded.value = value;
};
</script>

<style scoped>
.btn.grey {
  background: #f8f8fa;
  border: solid 1px #d0d5dd;
}

.current button {
  font-weight: bold;
  color: #184abc;
}
</style>
