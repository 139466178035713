<template lang="">
  <header class="header">
    <div class="container">
      <div class="header-inner">
        <router-link to="/" class="header-logo">
          <img
            src="/img/logo/logo.png"
            srcset="/img/logo/logo@2x.png 2x"
            alt="logo"
          />
        </router-link>
        <nav class="header-nav">
          <div class="container">
            <ul>
              <li v-if="isAuthenticated && !hiddenMenusWithoutProject">
                <router-link :to="`/overview/${selected_project}`"> Overview </router-link>
              </li>

              <li v-if="isAuthenticated && !isAdmin && !hiddenMenusWithoutProject">
                <router-link :to="`/projects/${customerStore.getId}`"> My Projects </router-link>
              </li>
              <!-- <li class="disabled">
                <router-link to="/customer-portal">
                  Customer portal
                </router-link>
              </li> -->
              <!-- <li class="disabled">
                <a href="#"> Virtuals </a>
              </li> -->
              <li v-if="isAuthenticated && (showOnlyForOwnerRole && !hiddenMenusWithoutProject)">
                <router-link 
                  :to="`/billing/${customerStore.getId}`"
                > Billing </router-link>
              </li>
              <!-- <li class="disabled">
                <router-link to="/support"> Support </router-link>
              </li> -->

              
              
              
              <li  class="itens-only-mobile"  v-if="isAuthenticated && isAdmin"> 
                <router-link 
                  to="/admin/customers" 
                >
                  Admin
                </router-link>
              </li>

              
              
              <li class="itens-only-mobile">
                <router-link 
                  v-if="isAuthenticated"
                  to="/my-account" 
                  id="my-account-btn"  
                >
                  My account
                </router-link>
              </li>
              <li class="itens-only-mobile">
                <button
                  v-if="isAuthenticated"
                  @click="onClickSignOut"
                  class="header-btn"
                >
                  Log out
                </button>

              </li>
            </ul>

            <div class="header-actions ">
              <div class="expand-action" :class="{'active': isExpand }" v-if="isAuthenticated" @click="expandListBlock">
                <i class="fa fa-expand" ></i>
                <span class="tooltip-text" v-if="isExpand">Retract</span>
                <span class="tooltip-text" v-if="!isExpand">Expand</span>
              </div>
              <!-- <router-link v-if="!isAuthenticated" to="/signup">
                <button
                  class="header-btn"
                  id="login-btn"
                >
                  Signup
                </button>
              </router-link> -->

              <button
                v-if="!isAuthenticated"
                @click="onClickSignIn"
                class="header-btn"
                id="login-btn"
              >
                Login
              </button>
              <router-link 
                v-if="isAuthenticated && isAdmin"
                to="/admin/customers" 
                class="header-btn"
                id="admin-btn"  
              >
                Admin
              </router-link>
              

              <router-link 
                v-if="isAuthenticated"
                to="/my-account" 
                class="header-btn"
                id="my-account-btn"  
              >
                My account
              </router-link>
              <button
                v-if="isAuthenticated"
                @click="onClickSignOut"
                class="header-btn"
              >
                Log out
              </button>
            </div>
          </div>
        </nav>
        <div class="hamburger hamburger--spin" @click="openMenu">
          <div class="hamburger-box">
            <div class="hamburger-inner"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="sub-header">
      <div class="container">
        <div class="sub-header-inner">
          <ul class="sub-header-menu">
            <li>
              <router-link to="/development-point" v-if="!hiddenMenusWithoutProject" >Buy Development Points </router-link>
            </li>
          </ul>
          <!-- <form action="#" class="sub-header-search">
            <svg>
              <use xlink:href="/img/svgsprite/svgsprite.svg#search"></use>
            </svg>
            <input type="text" placeholder="Search" />
          </form> -->
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { useCustomerStore } from "../../stores/customer";
import { useInterfaceStore } from '@/stores/interface'
import { useLogto } from "@logto/vue";
import { storeToRefs } from 'pinia'
import { getUri } from '../../utils'
import { inject, computed, ref, onMounted, defineEmits } from "vue";
import { useRouter } from "vue-router";

const emit = defineEmits(['expandListBlock'])
const customerStore = useCustomerStore();
const interfaceStore = useInterfaceStore();
const config = inject('runtimeConfiguration')
const isExpand = ref(true)
const router = useRouter()

const { selected_project, getSelectedProjectObject } = storeToRefs(interfaceStore)

const { signOut, signIn, getAccessToken } = useLogto();
const onClickSignIn = () => signIn(getUri(
  config.LOGTO_SIGNIN_CALLBACK || '/callback'
));

const showOnlyForOwnerRole = computed(() => {
  return getSelectedProjectObject?.value?.role !== "member";
});

const hiddenMenusWithoutProject = computed(() => {
  const selectedProject = getSelectedProject()
  return selectedProject === null || selectedProject === 'undefined';
})

const isAdminPage = computed(() => {
  return router.currentRoute.value.path.includes('/admin')
})

const expandListBlock = () => {
  isExpand.value = !isExpand.value
  localStorage.setItem('expandListBlock', isExpand.value)
  emit('expandListBlock', isExpand.value) 
}

const onClickSignOut = () => {
  customerStore.reset();
  interfaceStore.reset();
  signOut(getUri(
    config.LOGTO_SIGNOUT_REDIRECT || '/'
  ));
};

const isAuthenticated = customerStore.isAuthenticated

const isAdmin = computed(() => {
  const customer = customerStore._customer;
  if(!customer || customer.role == undefined)
    return false;
  else
    return customer.role == "admin";
})

const openMenu = () => {
  const hamburger = document.querySelector('.hamburger');
  const headerNav = document.querySelector('.header-nav');
  hamburger.classList.toggle('is-active');
  headerNav.classList.toggle('active');
}

const handleExpand = () => {
  const handleExpandValue = JSON.parse(localStorage.getItem('expandListBlock'))
  if(handleExpandValue !== null) {
    isExpand.value = handleExpandValue
  }
  emit('expandListBlock', isExpand.value)
}

const getSelectedProject = () => {
  const value = localStorage.getItem('selected_project') 

  if(value === 'undefined' || value === null || value === 0) {
    return null;
  }

  return JSON.parse(value)
}

onMounted(() => {
  handleExpand()
})

</script>
<style>

@media (min-width: 0px) {
  .expand-action {
    display: none !important;
  }
  .header-nav ul .itens-only-mobile {
    display: block;
  }
}

@media (min-width: 850px) {
  .expand-action {
    display: block !important;
  }
  .header-nav ul  .itens-only-mobile {
    display: none;
  }
}

.expand-action {
  cursor: pointer;
  padding: 10px;
  color: #fff;
  margin-right: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;
}

.expand-action.active {
  color: #2E86C1;
}

/* Tooltip text */
.tooltip-text {
  visibility: hidden;
  width: 100px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: -125%; /* Position the tooltip above the text */
  left: 50%;
  
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* Show the tooltip text when hovering over the container */
.expand-action:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}


</style>